import React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Img from "gatsby-image";
import Layout from "../components/layout";
import "../styles/title-img.sass";
import "../styles/contact.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/footer";

const Contact = ({ data: { allDatoCmsSocialProfile, datoCmsContact , datoCmsHome  } }) => (
  <Layout>
    <div className="title-container">
    <HelmetDatoCms seo={datoCmsContact.seoMetaTags} />
      <div className="title-image">
        <div className="title">
          <h1>{datoCmsContact.title}</h1>
        </div>
        <Img fluid={datoCmsContact.contactTitleImage.fluid} />
      </div>
    </div>
    <section className="contact-page">
      <div className="map-image">
      <Img
          fluid={datoCmsContact.mapImage.fluid}
        />
      </div>
      <div className="contact-info">
        <h1>Contact Us To Arrange A Quote</h1>
      <div className="contact-email">
          <FontAwesomeIcon icon={faEnvelope} />
          <a href={"mailto:" + datoCmsHome.email}>{datoCmsHome.email}
          </a>
      </div>
      <div className="contact-phone">
        <FontAwesomeIcon icon={faPhone} />
        <a href={"tel:" + datoCmsHome.telephoneNumber}>
          {datoCmsHome.telephoneNumber}
        </a>

      </div>
      <div className="footer-social">
                {allDatoCmsSocialProfile.edges.map(({ node: profile }) => (
                  <a
                    key={profile.profileType}
                    href={profile.url}
                    target="blank"
                    className={`social social--${profile.profileType.toLowerCase()}`}
                  >
                    {" "}
                  </a>
                ))}
              </div>
      </div>
    </section>

    <Footer />
  </Layout>
);

export default Contact;

export const query = graphql`
query ContactQuery {
  allDatoCmsSocialProfile(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          profileType
          url
        }
      }
    }
      datoCmsContact {
        title
        contactTitleImage {
          fluid(imgixParams: {fm: "jpg", auto: "compress"}) {
            src
          }
        }
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        mapImage {
          fluid(imgixParams: {fm: "jpg", auto: "compress"}) {
            src
          }
        }
      }
      datoCmsHome {
        email
        telephoneNumber
      }
    }
`;
